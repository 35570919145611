import React from 'react'
import s from './Payment.module.sass'
import Sofa from '../../components/Sofa/Sofa'
function Payment() {
  return (
    <div className={s.container}>
        <div className={s.innerContainer}>
            <div className={s.titleText}>Клиентам</div>
            <div className={s.subTitleText}>Условие принятие заказа на корпусную мебель</div>
            <p className={s.allText}>
            Обращаем Ваше внимание, что при оформлении заказов на корпусную мебель, сроки исполнения обязательств продавца по поставке товара исчисляются с момента подписания сторонами полного пакета документов, включающих в себя:<br/>

а) Договор купли-продажи товара, включающий спецификацию.<br/>
б) План помещений и схемы расстановки товара.<br/>
в) Чертежи индивидуального изготовления мебели (кухня, гардеробная, стеллаж, библиотека и т.д.)<br/>
г) Замеры помещений.<br/>
В случае каких-либо изменений размеров помещения покупателя после проведенных и подписанных сторонами замеров, все дополнительные работы и дозаказы производятся за счет покупателя.<br/>
<br/>
При отказе покупателя от замеров или невозможности их проведения, продавец выдает «Техническое задание», в котором указываются необходимые требования к размерам стен и высот помещений для сборки мебели, которые покупатель обязан выполнить. При несоблюдении Технического задания, выданного продавцом, все дополнительные работы и дозаказы производятся за счет покупателя.<br/>
<br/>
При покупке кухонного гарнитура покупатель дополнительно предоставляет:<br/>

1) схему выводов для кухни (электрика и водоснабжение).<br/>
2) полный перечень техники для кухни (в случае, если техника приобретается у сторонних поставщиков) с указанием фирм-производителей и артикулов моделей, со схемами встраивания.<br/>
3) утвержденный покупателем тип столешницы и ее толщина (в случае, если столешница/фартук приобретается у сторонних поставщиков) для понимания возможности встраивания варочной панели и мойки.<br/>
В случае утверждения нестандартной отделки товара, стоимость заказа увеличивается на сумму изготовления и доставки образца, при этом срок поставки товара будет определен с момента утверждения покупателем нестандартного образца.<br/>
            </p>

            <Sofa/>
        </div>
    </div>
  )
}

export default Payment